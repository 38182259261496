import styled from 'styled-components';

export const ErrorContent = styled.div`
  width: 500px;
  max-width: 100%;
  margin: 0 auto;
  .image-wrapper {
    @media (max-width: 1480px) {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const ButtonWrapper = styled.div`
  font-family: 'roboto';
  text-align: center;
  > * {
    margin: 0 8px;
  }
  .reload {
    background-color: #9b4fff;
  }
`;
