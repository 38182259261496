import React from "react";
import ErrorSec from "containers/Error";

const NotFoundPage = () => {
  return (
    <>
      <ErrorSec />
    </>
  );
};

export default NotFoundPage;
